<template>
  <div style="">
    <!-- <div class="comparisons"> -->
        <!-- 属性名 -->
        <!-- <div style="padding-top: 150px;">
            <div v-for="(item, i) in propertys" :key="i">{{ item }}</div>
        </div> -->
        <!-- 产品详情 -->
        <!-- <div class="details" v-for="(item, index) in list" :key="index">
            <div><img :src="'https://resources.holowits.com/' + item.product.imageUrl" alt="" style="width: 200px; height: 150px;"></div>
            <div v-for="(property, i) in propertys" :key="i">
                <div>{{ item.propertys[property] }}</div>
            </div>
        </div> -->
    <!-- </div> -->
    <div class="product-comparison">
        <div ref="headers">
            <div class="header" ref="header">
                <div style="display: flex; align-items: center;">
                    <div class="t-panel" style="width: 200px;">
                        <div class="title">Product Comparison</div>
                        <div class="count">{{ list.length }} / 4</div>
                        <div>
                            <el-checkbox-group v-model="checkList" @change="fun1">
                                <el-checkbox label="1">Highlights Differences</el-checkbox>
                                <el-checkbox label="2">Hide Identical Items</el-checkbox>
                            </el-checkbox-group>
                        </div>
                    </div>
                </div>
                <div class="product" v-for="(item, index) in list" :key="index" style="width: 230px;">
                    <div><img :src="domainUrl + item.imageUrl" alt="" style="width: 230px; height: 150px;"></div>
                    <div><p>{{ item.name }}</p></div>
                </div>
            </div>
        </div>

        <div class="property-group" v-for="category in datas.sortNum" :key="category">
            <p class="category-title">{{ category }}</p>
            <template v-for="property in datas[category].sortNum">
                <div :class="['propertys', equal  ? 'equal': '', isHide && datas[category].property[property].equality ? 'hide' : '']" :key="property">
                    <div class="property-title" style="width: 200px;">{{ property }}</div>
                    <div :class="['property', datas[category].property[property].equality ? '' : 'value']">
                        <template v-for="(item, index) in datas[category].property[property].productValue">
                            <div style="width: 230px; text-align: center; margin-left: 20px;" :key="index">{{ item }}</div>
                        </template>
                    </div>
                </div>
            </template>
        </div>

    </div>
  </div>
</template>

<script>
import {getTempByType, comparison} from '@/api/product'
import db from '@/utils/localstorage';
import draggable from 'vuedraggable'
export default {
name: 'Comparison',
components: {
    draggable
},
async created() {
    // 首先获取到 vuex 或者 本地存储空间 里的对比数据
    this.comparisonList = db.get("COMPARISON_LIST", "")

    await this.getTempByType()
    await this.comparison()
},
async mounted() {
    window.addEventListener('scroll', this.a1)
},
beforeDestroy() {
    window.removeEventListener('scroll', this.a1)
},
data() {
    return {
        comparisonList: [],
        propertys: {},
        list: [],
        headers: [],
        datas: {},
        checkList: [],
        equal: false,
        isHide: false,
        scrollStatus: 'unactive'
    }
},

methods: {
    // 滑动设置
    a1() {
        let scrollTop = document.documentElement.scrollTop || document.body.scrollTop;

        if (scrollTop > 0) {
            if (this.scrollStatus === 'unactive') {
                this.scrollStatus = 'active'

                let header = this.$refs['header'];
                let offsetHeight = header.offsetHeight;

                let headers  = this.$refs['headers'];
                headers.style.height = `${offsetHeight}px`

                header.style.position = 'fixed';
                header.style.top = '100px';

            }
        } else {
            this.scrollStatus = 'unactive'
            let header = this.$refs['header'];
            header.style.position = 'relative';
            header.style.top = '0px';
        }
    },
    fun1(value) {
        if (value.includes('1')) {
            this.equal = true
        } else {
            this.equal = false
        }
        if (value.includes('2')) {
            this.isHide = true
        } else {
            this.isHide = false
        }
    },
    async getTempByType() {
        let templateName = this.comparisonList[0]['productClass'] || 'camera'
        const {data: {data, isSuccess}} = await getTempByType({
            productType: templateName
        })
        if (isSuccess) {
            console.log('111111---------------------------',new Date())
            let arr = {
                sortNum: []
            }

            for (let item of data) {
                arr[item.category] = {
                    category: item.category,
                    property: {},
                    sortNum: []
                }
                arr.sortNum.includes(item.category) || arr.sortNum.push(item.category)
            }

            for (let item of data) {
                arr[item.category].property[item.property] = {
                    type: item.property,
                    productValue: [],
                    equality: false
                }
                arr[item.category].sortNum.push(item.property)
            }

            this.propertys = arr
            console.log('111111---------------------------',new Date())
        }
    },
    async comparison() {
        let ids = []
        for(let item of this.comparisonList) {
            ids.push(item.productId)
        }
        const {data: {data, isSuccess}} = await comparison({
            productId: ids
        })
        if (isSuccess) {
            console.log('222222---------------------------',new Date())
            this.list = data.map(item => item.product)
            for (let item of data) {
                for (let {category, property, value} of item.specificationNewDTOs) {
                    this.propertys[category]['property'][property].productValue.push(value)
                    this.propertys[category]['property'][property].equality = 
                        new Set(this.propertys[category]['property'][property].productValue).size === 1 ? true : false
                }
            }
            this.datas = this.propertys
            console.log('222222---------------------------',new Date())
        }
    }
}
}
</script>

<style>
    .page-on-scroll .navbar {
        border-bottom: 0px solid rgb(230 239 243) !important; 
    }
</style>

<style lang="scss" scoped>
.product-comparison {
    width: 1200px;
    margin: 0 auto;
    background-color: #ffffff;
    padding-bottom: 20px;

    .comparisons {
        display: flex;

        .details {
            margin-left: 10px;
        }
    }

    .header {
        display: flex;
        // align-items: center;
        text-align: center;
        padding-bottom: 20px;
        border-bottom: 1px solid #dcdfe6;

        background-color: #ffffff;
        width: 1200px;
        margin: auto;

        .t-panel {
            text-align: left;
            .title {
                font-size: 18px;
                font-weight: 600;
            }
            .count {
                font-size: 16px;
                font-weight: 600;
                text-align: center;
            }

            div {
                margin-bottom: 10px;
            }

            ::v-deep .el-checkbox-group {
                text-align: left;
                .el-checkbox {
                    margin-right: 0px !important;
                    margin-bottom: 10px;
                }
            }
        }

        .product {
            display: flex;
            flex-direction: column;
        }

        .product:not(:first-child) {
            margin-left: 20px;
            // margin-left: 20px;
            // border: 1px solid gray;
        }
    }

    .property-group {
        margin-top: 50px;
        .category-title {
            font-size: 26px;
            font-weight: 600;
            // margin-bottom: 10px;
            padding-left: 15px;
            padding-bottom: 10px;
            border-bottom: 1px solid #dcdfe6;
        }
        .propertys {
            display: flex;
            
            // &:nth-child(odd) {
            //     background-color: #fae9e9;
            // }
            border-bottom: 1px solid #dcdfe6;

            .property-title {
                // font-size: 16px;
                font-weight: 600;
                padding-left: 20px;
            }

            .property {
                display: flex;
                // div {
                //     border: 1px solid gray;
                // }
            }

            & > div {
                padding: 15px 0;
            }
            
        }

        .hide {
            display: none;
        }

        .equal {
            .value {
                color: #c7000b;
            }
        }
    }

    // .content {
    //     display: flex;
    //     align-items: center;
    //     div:not(:first-child) {
    //         margin-left: 20px;
    //         text-align: center;
    //     }
    //     &:last-child {
    //         margin-bottom: 10px;
    //     }
    // }
    // .propertys {
    //     padding: 10px 0;
    //     &:nth-child(even) {
    //         background-color: #fae9e9;
    //     }
    // }
}
</style>